import React from "react";
//import PropTypes from "prop-types";
import { css } from "@emotion/core";

const Content = ({ children }) => (
  <div
    css={css`
      //padding: 50px;
    `}
  >
    <div
      css={css`
        margin-top: -60px;
        @media (min-width: 992px) {
          margin: -60px 30px 0 30px;
        }
        //padding: 3px;
        border-radius: 2px;
        box-shadow: 0 6px 12px 5px rgba(0, 0, 0, 0.15);
        position: relative;
        background-color: white;
        z-index: 2;
      `}
    >
      {children}
    </div>
  </div>
);

export default Content;
