//import "core-js/es6/map";
//import "core-js/es6/set";

//import "core-js/stable";

import React from "react";

import Layout from "../components/layout";

import Img from "gatsby-image";

import { graphql, Link } from "gatsby";

import PageHeader from "../components/pageheader";
import HEAD from "../components/head";
import CONTENT from "../components/content";
import SECTION from "../components/section";
import { css } from "@emotion/core";

import logo from "../images/redapple_Final_Lightgray.png";

import img_puzzle from "../images/puzzle.png";
import img_pbank from "../images/pbank.png";
import img_balance from "../images/balance.png";
import img_stars from "../images/stars.png";

const css_section_header_divider = css`
  height: 240px;
  border-right: 2px solid gray;
  width: 50%;
`;

const css_section_padding = css`
  @media (min-width: 992px) {
    padding: 25px 75px;
  }
  padding: 25px;
`;

const IndexPage = ({ data }) => (
  <Layout>
    <HEAD
      title="Home"
      keywords={[
        `red apple solutions`,
        `education insurance`,
        `school employee insurance`,
        `insurance for schools`,
        `school insurance`,
        `college insurance`,
        `university insurance`,
        `school employee benefits`
      ]}
    />
    <PageHeader
      css={css`
        height: 90vh;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          margin: 0;
          padding: 0;
          height: 100%;
        `}
      >
        <Img
          title="Chalkboard"
          alt="Chalkboard background image"
          //fluid={data.chalkboard.childImageSharp.fluid}
          fluid={data.chalkboard.childImageSharp.fluid}
          css={css`
            position: inherit !important;
          `}
        />
        <div className="container">
          <div className="row">
            <div className="col-lg-8 ml-auto mr-auto">
              <div
                css={css`
                  text-align: center;
                  color: white;
                `}
              >
                <img
                  css={css`
                    width: 100%;
                    max-width: 602px;
                  `}
                  src={logo}
                  alt="Red Apple Solutions - logo"
                />
                <p
                  css={css`
                    padding-top: 25px;
                    font-size: 22px;
                    text-transform: uppercase;
                    letter-spacing: 6px;
                    color: #c1c1c1;
                  `}
                >
                  Meeting the Unique Needs of Educational Institutions{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageHeader>
    <CONTENT>
      <SECTION>
        <div className="row" css={css_section_padding}>
          <div
            className="col-lg-3 align-middle"
            css={css`
              min-width: 220px;
            `}
          >
            <div className="text-apple section-header">What We Do</div>
          </div>
          <div className="col-lg align-middle d-none d-lg-block">
            <div css={css_section_header_divider} />
          </div>
          <div className="col-lg-8 ml-auto mr-auto">
            <div className="description">
              {/*<h5 className="text-apple text-pop">Red Apple is different</h5>*/}
              <p>
                Red Apple Solutions is an industry expert in life and disability
                products for educational institutions.
              </p>

              <p>
                As experts on school systems as a whole, we understand the
                importance of employee morale, engagement and retention.
              </p>

              <p>
                We understand that teachers rely on high quality benefits at the
                same time educational institutions need rate stability and cost
                savings. To meet both those needs, we lower costs to educational
                institutions.
              </p>
            </div>
          </div>
        </div>
      </SECTION>

      <SECTION
        css={css`
          background-color: rgb(150, 42, 34);

          color: rgb(230, 230, 230);
          text-align: center;
        `}
      >
        <div className="row">
          <div className="col-md">
            <div className="info">
              <div className="icon">
                <i>
                  <img src={img_puzzle} alt="icon - 4 piece puzzle" />
                </i>
              </div>
            </div>

            <div className="info-header">COMPREHENSIVE OFFER</div>
            <p className="info-description">
              Provide the best possible benefits at the lowest possible cost
            </p>
          </div>
          <div className="col-md">
            <div className="info">
              <div className="icon">
                <i>
                  <img src={img_stars} alt="icon - 5 stars, 5 points" />
                </i>
              </div>
            </div>

            <div className="info-header">HIGH QUALITY</div>
            <p className="info-description">
              Red Apple Solutions provides unique aggregated underwriting
              capabilities and expert plan design analysis
            </p>
          </div>
          <div className="col-md">
            <div className="info">
              <div className="icon">
                <i>
                  <img src={img_balance} alt="icon - balanced lever " />
                </i>
              </div>
            </div>
            <div className="info-header">RATE STABILITY</div>
            <p className="info-description">
              Red Apple Solutions stays up-to-date on education trends on local
              and national levels and offers significant cost savings and rate
              stability for educational institutions
            </p>
          </div>
          <div className="col-md">
            <div className="info">
              <div className="icon">
                <i>
                  <img src={img_pbank} alt="icon - piggy bank" />
                </i>
              </div>
            </div>
            <div className="info-header">COST EFFECTIVE</div>
            <p className="info-description">
              Reduce costs and stabilize rates by utilizing the aggregated
              volume of all members
            </p>
          </div>
        </div>
      </SECTION>

      <section
        css={css`
          height: 637px;
          overflow: hidden;
          position: relative;
        `}
      >
        <Img
          //title="Smiling teacher"
          alt="Smiling teacher in front of green chalkboard"
          css={css`
            min-width: 1200px;
          `}
          fluid={data.teacher1.childImageSharp.fluid}
        />
        <div
          className="container"
          css={css`
            position: absolute;
            left: 0;
            right: 0;
            top: 40%;
          `}
        >
          <div
            className="row text-center"
            css={css`
              color: white;
              text-transform: uppercase;
            `}
          >
            <div className="col-lg-5" />
            <div className="col-lg-7 ml-auto mr-auto">
              <h1 className="description-block text-center">
                <p>Educational employees work hard for their communities</p>
                <br />
                <p>
                  One thing they should never have to worry about is their
                  benefits
                </p>
              </h1>
            </div>
          </div>
        </div>
      </section>
      <SECTION id="about">
        <div className="row" css={css_section_padding}>
          <div className="col-lg align-middle">
            <div className="text-apple section-header">About Us</div>
          </div>
          <div className="col-lg align-middle d-none d-lg-block">
            <div css={css_section_header_divider} />
          </div>
          <div className="col-lg-8 ml-auto mr-auto">
            <div className="description">
              <p>
                Educational institutions deserve experts who understand the
                culture and mission of schools, as well as their needs and how
                they operate in today’s world.
              </p>

              <p>
                Red Apple Solutions is rooted in expertise and knowledge of the
                needs of educators, understanding the issues they face as they
                juggle changing curriculums, classroom management, and
                extracurricular activities, all while focusing  on their primary
                mission of helping students to learn and thrive.
              </p>

              <p>
                We are dedicated to exclusively serving educational
                institutions. Our insurance experts teamed up with former
                educators and educational administrators with decades of
                experience in schools in order to provide the best possible
                benefits at the lowest possible cost.
              </p>
              <p>
                Most importantly, we provide compassionate service and excellent
                benefits that give educational employees peace of mind.
              </p>
            </div>
          </div>
        </div>
      </SECTION>

      <section
        css={css`
          height: 637px;
          overflow: hidden;
          position: relative;
          //background-color: #636e68;
        `}
      >
        <Img
          //title="Teacher2"
          alt="Smiling teacher in glasses"
          css={css`
            min-width: 1200px;
          `}
          fluid={data.teacher2.childImageSharp.fluid}
        />
        <div
          className="container"
          css={css`
            position: absolute;
            left: 0;
            right: 0;
            top: 40%;
          `}
        >
          <div
            className="row text-center"
            css={css`
              text-transform: uppercase;
              color: white;
            `}
          >
            <div className="col-md-7 ml-auto mr-auto">
              <h1 className="description-block text-center text-apple">
                <p>Educators serve and shape communities</p>
                <br />
                <p>We serve and protect educators</p>
              </h1>
            </div>
            <div className="col-md-5" />
          </div>
        </div>
      </section>

      <SECTION
        id="solutions"
        css={css`
          background-color: rgb(239, 230, 231);
        `}
      >
        <div className="row" css={css_section_padding}>
          <div className="col-lg align-middle">
            <div className="text-apple section-header">Solutions</div>
          </div>

          <div className="col-lg align-middle d-none d-lg-block">
            <div css={css_section_header_divider} />
          </div>
          <div className="col-lg-8 ml-auto mr-auto">
            <div className="description">
              <h5 className="text-apple text-pop">Aggregation</h5>
              <p>
                Reduce costs and stabilize rates by utilizing the aggregated
                volume of all members
              </p>
            </div>
            <div className="description">
              <h5 className="text-apple text-pop">Collaboration</h5>
              <p>
                Provide the platform for participatory educational institutions
                to share best practices and data
              </p>
            </div>
            <div className="description">
              <h5 className="text-apple text-pop">Rate stability</h5>
              <p>
                Aggregate rate factors, census demographics, claims management
                and benefit plan design to predict and lower costs
              </p>
            </div>
            <div className="description">
              <h5 className="text-apple text-pop">Communication</h5>
              <p>
                Enable enhanced communication of benefits program to employees
              </p>
            </div>
            <div className="description">
              <h5 className="text-apple text-pop">Operational efficiancies</h5>
              <p>
                Streamline processes to optimize time spent managing core HR
                issues
              </p>
            </div>
          </div>
        </div>
      </SECTION>
      <SECTION
        css={css`
          background-color: #49484e;
          color: white;
          padding-top: 1rem;
        `}
      >
        <div className="row">
          <div className="col-md-3 ml-auto mr-auto">
            Contact
            <address>
              1389 Center Dr <br />
              Suite 200
              <br />
              Park City, UT 84098
            </address>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3 ml-auto mr-auto">
            <Link to={`privacy-policy`}>Privacy Policy</Link>
          </div>
        </div>
      </SECTION>
    </CONTENT>
    {/*<Image />*/}
  </Layout>
);

export default IndexPage;

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 1920) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

export const query = graphql`
  query {
    chalkboard: file(relativePath: { eq: "chalkboard.jpg" }) {
      ...fluidImage
    }
    teacher1: file(relativePath: { eq: "t1-2-flipped.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    teacher2: file(relativePath: { eq: "t2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
