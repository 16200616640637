import React from "react";
//import PropTypes from "prop-types";
import { css } from "@emotion/core";

const Section = ({ children, ...props }) => {
  return (
    <section
      css={css`
        padding-top: 60px;
        background-color: white;
      `}
      {...props}
    >
      <div className="container">{children}</div>
    </section>
  );
};

export default Section;
